import React from "react";


export const sectionOne = {
  topTitle: 'Web Design & Digital Marketing',
  topSubTitle: 'You know you need a web site, but what do you need and who do you choose? ' +
    'At BeeFrameworks in Leeds, West Yorkshire, we design and develop search engine friendly, hand-crafted websites and we offer digital ' +
    'marketing services aimed at helping your business increase traffic and generate leads.',
  cardOneTitle: 'Web Design',
  cardOneDesc: [
    'Understanding your priorities and type of website you require is the first step.',
    'Followed by a road map, be it a WordPress or bespoke site. We create a strategy to succeed.',
    'Then it\'s time to get to work. Our team gets things done.'
  ],
  cardOneDescPt2: 'Because of our pay monthly pricing' +
    '    \'model, it means our services are affordable without compromising on quality.',
  cardTwoTitle: 'Digital Marketing',
  cardTwoDesc: [
    'SEO (Search Engine Optimisation)', 'Social Media Marketing', 'Email Marketing', 'Pay Per Click Management'
  ]
}

export const sectionTwo = {
  topTitle: 'IT Support & Software Development',
  topSubTitle: 'We provide cost-effective IT support services, we have experience in implementing and supporting\n' +
    '          Microsoft-based IT infrastructures. We build and maintain software by leveraging cutting edge technology.\n' +
    '          In short, we provide an end-to-end support for all your software needs.',
  cardOneTitle: 'IT Support',
  cardOneDesc: ['Microsoft Systems', 'Cyber Security', 'Computer Networks', 'Cloud Solutions',
  ],
  cardTwoTitle: 'Software Development',
  cardTwoDesc: [
    'We take great pride in helping our clients run their business processes as efficient as possible',
    'Our goal is to synchronise your objectives with our technical solution. Our experts will help you improve system ' +
    'reliability, performance and productivity through innovation'
  ]
}