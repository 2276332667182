import React from 'react';

const UnderConstruction = () => {
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f0f0f0',
    },
    box: {
      textAlign: 'center',
      backgroundColor: '#fff',
      padding: '30px',
      borderRadius: '10px',
      boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
    },
    heading: {
      fontSize: '3rem',
      marginBottom: '20px',
      color: '#ff9800', // Orange color for the construction sign
    },
    paragraph: {
      fontSize: '1.2rem',
      color: '#333',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.box}>
        <h1 style={styles.heading}>🚧 Site Under Construction 🚧</h1>
        <p style={styles.paragraph}>
          We are working hard to bring you a better experience.
        </p>
        <p style={styles.paragraph}>Please check back soon!</p>
      </div>
    </div>
  );
};

export default UnderConstruction;
